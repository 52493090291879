import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {Button, Paper} from "@material-ui/core";

import "./styles.css"
import queryString from "query-string";

const ThankYou = () => {
    let {url, guid} = queryString.parse(window.location.search);

    if (!url || url == 'None'){
        url = 'https://ueni.com'
    }

    const [timer, setTimer] = useState(5);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => {
                const new_time = timer - 1;

                if (timer === 1){
                    clearInterval(interval)
                }

                return new_time
            })
        }, 1000)
    }, [])

    const doRedirect = useCallback(() => {
        if (typeof url === "string"){
            window.location.href = url;
        }
    }, [url])

    useEffect(() => {
        if (timer === 0) {
            doRedirect()
        }
    },[timer, doRedirect])

    useLayoutEffect(() => {
        let obj = {
            'plan_code': 'ueni_lifetime_2021',
        };

        if (guid){
            obj['external_id'] = guid;
        }

        fbq('track', 'CompleteRegistration', obj);
    }, [guid])

    const timerNode = url && timer > 0 ? (
        <p className="thank-you-redirect-notice">You will be redirected in {timer} {timer === 1 ? "second" : "seconds"}</p>
    ) : null;

    return(
        <div className={"thank-you-wrapper"}>
            <Paper className="thank-you-paper">
                <p className="thank-you-title">Thank you!</p>
                <p className="thank-you-description mb-0">We received your payment and started to create your website.</p>
                <p className="thank-you-description">Please do not forget to fill the forms we will send to you.</p>
                <Button variant="contained" color="primary" onClick={doRedirect}>
                    Ok
                </Button>
                {timerNode}
            </Paper>
        </div>
    )

}

export default ThankYou;