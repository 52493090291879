import React, {useRef, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import SignUp from "./screens/SignUp"
import PaymentFailure from "./screens/PaymentFailure";
import ThankYou from "./screens/ThankYou";
import queryString from "query-string";

function App() {
    const {success} = queryString.parse(window.location.search);

    switch (success){
        case "true": {
            return <ThankYou />
        }
        case "false": {
            return <PaymentFailure />
        }
        default: {
            return <SignUp />
        }
    }
}

export default App;
