import queryString from "query-string";
import React, {useLayoutEffect, useRef, useState} from "react";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import startCase from "lodash/startCase";
import {createTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {Button, FormControl, FormHelperText, InputLabel, OutlinedInput, Paper} from "@material-ui/core";
import map from "lodash/map";

import 'react-phone-number-input/style.css'
import "./styles.css";

const theme = createTheme({
    overrides: {
        MuiInputBase: {
            root: {
                height: 40,
            }
        },
        MuiFormLabel: {
            root: {
                backgroundColor: "#FFFFFF",
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem"
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    inputFormControl: {
        marginBottom: "1rem"
    },
    phoneOutlinedInput: {
        display: "flex",
        alignItems: "base"
    }
}));

const CustomPhoneInput = (props) => {
    return (
        <PhoneInput defaultCountry="US" international withCountryCallingCode {...props}/>
    )
}

function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const SignUp = () => {
    const {email = "", phone_number = "", name = "", country, ...search} = queryString.parse(window.location.search);

    const formRef = useRef<any>();

    const [nameInput, setNameInput] = useState<any>(name)
    const [nameError, setNameError] = useState<any>(false)

    const [emailInput, setEmailInput] = useState<any>(email)
    const [emailError, setEmailError] = useState<any>(false)

    const [phoneNumberInput, setPhoneNumberInput] = useState<any>(phone_number);
    const [phoneNumberError, setPhoneNumberError] = useState<any>(false);

    const classes = useStyles();

    const onSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const invalidName = (nameInput.length<4);
        const invalidEmail = !isValidEmail(emailInput);
        const invalidPhoneNumber = !isValidPhoneNumber(phoneNumberInput);

        if(!invalidEmail && !invalidPhoneNumber && !invalidName) {
            formRef.current.submit()
        } else {
            if(invalidEmail) {
                setEmailError(true)
            } else {
                setEmailError(false)
            }
            if(invalidPhoneNumber){
                setPhoneNumberError(true);
            } else{
                setPhoneNumberError(false);
            }
            if(invalidName){
                setNameError(true);
            } else{
                setNameError(false);
            }
        }
    }

    const title = ["Hello",  startCase(search.first_name)].filter(Boolean).join(", ");

    useLayoutEffect(() => {
        document.querySelector(".PhoneInputCountrySelect")?.setAttribute("tabindex", "-1");
    }, [])

    const price = country === "gb" ? "£49" : "$97";

    return (
        <ThemeProvider theme={theme}>
            <div className="signup-wrapper">
                <form action={process.env.REACT_APP_SIGNUP_ACTION_URL} method="POST" ref={formRef} onSubmit={onSubmit}>
                    <Paper className="signup-paper">
                        <p className="signup-title">{title}!</p>
                        <p className="signup-description">You are only a few clicks away from getting a brand new website for a <strong>one-time payment of {price}.</strong></p>

                        <FormControl  size={"small"} variant="outlined" className={classes.inputFormControl}>
                            <InputLabel shrink htmlFor="name" variant="outlined" error={nameError}>Business Name</InputLabel>
                            <OutlinedInput
                                notched
                                value={nameInput}
                                onChange={(e) => setNameInput(e.target.value)}
                                name="name"
                                id={"name"}
                                error={nameError}
                            />
                            {
                                nameError && (
                                    <FormHelperText variant="outlined" error>Wrong business name</FormHelperText>
                                )
                            }
                        </FormControl>

                        <FormControl  size={"small"} variant="outlined" className={classes.inputFormControl}>
                            <InputLabel shrink htmlFor="email" variant="outlined" error={emailError}>Email</InputLabel>
                            <OutlinedInput
                                notched
                                value={emailInput}
                                onChange={(e) => setEmailInput(e.target.value)}
                                name="email"
                                id={"email"}
                                error={emailError}
                            />
                            {
                                emailError && (
                                    <FormHelperText variant="outlined" error>Invalid email</FormHelperText>
                                )
                            }
                        </FormControl>

                        <FormControl size={"small"} variant="outlined" className={classes.inputFormControl}>
                            <InputLabel shrink htmlFor="phone_number" variant="outlined" error={phoneNumberError}>Phone number</InputLabel>
                            <OutlinedInput
                                notched
                                value={phoneNumberInput}
                                onChange={setPhoneNumberInput}
                                autoFocus={true}
                                name="phone_number"
                                id={"phone_number"}
                                inputComponent={CustomPhoneInput}
                                error={phoneNumberError}
                                inputProps={{className: classes.phoneOutlinedInput}}
                            />
                            {
                                phoneNumberError && (
                                    <FormHelperText variant="outlined" error>Invalid phone number</FormHelperText>
                                )
                            }
                        </FormControl>

                        {
                            map(search, (value, key) => (
                                <input hidden name={key} value={value} key={key}/>
                            ))
                        }

                        <Button variant="contained" color="primary" type="submit">
                            Sign up
                        </Button>

                    </Paper>
                    <p className="signup-have-an-account">Already have an account? <a href="https://ueni.com/login">Log in</a></p>
                </form>
            </div>
        </ThemeProvider>
    );
}

export default SignUp
