import React, {useCallback, useEffect, useState} from "react";
import {Button, Paper} from "@material-ui/core";

import "./styles.css"
import queryString from "query-string";

const PaymentFailure = () => {
    let {url} = queryString.parse(window.location.search);
    if (!url || url == 'None'){
        url = 'https://ueni.com'
    }

    const [timer, setTimer] = useState(5);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => {
                const new_time = timer -1;

                if (timer === 1){
                    clearInterval(interval)
                }

                return new_time
            })
        }, 1000)
    }, [])

    const doRedirect = useCallback(() => {
        if (typeof url === "string"){
            window.location.href = url;
        }
    }, [url])

    useEffect(() => {
        if (timer === 0) {
            doRedirect()
        }
    },[timer, doRedirect])

    const timerNode = url && timer > 0 ? (<p className="payment-failure-redirect-notice">You will be redirected in {timer} {timer === 1 ? "second" : "seconds"}</p>) : null;

    return(
        <div className={"payment-failure-wrapper"}>
            <Paper className="payment-failure-paper">
                <p className="payment-failure-title">Sorry, payment failed!</p>
                <p className="payment-failure-description">Please finish your payment at UENI.com</p>
                <Button variant="contained" color="primary" onClick={doRedirect}>
                    Ok
                </Button>
                {timerNode}
            </Paper>
        </div>
    )
}

export default PaymentFailure;